<template>
  <div>
    <div class="bottomBox">
      <div style="display: flex; align-items: center;">
        河南省豫资大数据研究院有限公司版权所有，未经书面授权禁止使用 <br />
        <!-- Copyright © 2022-2023 by www.train.hnzyxsbh.com all rights reserved -->
        Copyright © 2022-2023 by www.yuzidsj.com all rights reserved
      </div>
    </div>
  </div>
  <!-- <div class="cinonimg">www.yuzidsj.com
        <img src="@/assets/button/copy_icon1.png" alt="" srcset="">
           <img src="@/assets/button/copy_icon2.png" alt="" srcset="">
              <img src="@/assets/button/copy_icon3.png" alt="" srcset="">
                 <img src="@/assets/button/copy_icon4.png" alt="" srcset="">
                    <img src="@/assets/button/copy_py2.png" alt="" srcset="">
                       <img src="@/assets/button/wzahg.png" alt="" srcset="">
      </div> -->
</template>

<script>
import { bhprocess } from '@/api/user'
import $ from 'jquery'
export default {
  data() {
    return {
      tangs: false,
      zhangs: false,
      imgName: '../../assets/login/tang1.png'
    }
  },
  created() {
    this.asjhdja() //判断url是否存在
  },
  methods: {
    async asjhdja() {
      var bbc = sessionStorage.getItem('applyno')
      //       var datas={applyno:sessionStorage.getItem("applyno")}
      //       // debugger
      //       $.support.cors = true;
      //       $.ajax({
      //                 url: "http://www.hnzyxsbh.com/tripartite/web/xbhan/bhprocess",
      //                 dataType: "text",
      //                 async: true,
      //                 type: 'post',
      //                 cache: false,
      //                 crossDomain: true == !(document.all),
      //                 success: function (data) {
      //                     alert(data);
      //                 }
      //             });
      // // $.ajax({
      // //   type: 'POST',
      // //   url: 'http://www.hnzyxsbh.com/tripartite/web/xbhan/bhprocess?'+"applyno="+bbc,
      // //   data: datas,
      // //      dataType: "json",
      // //   success: function(err){
      // //                        // console.log("请求1",err)
      // //                       },
      // // });
      //   return
      // $.ajax({
      //   url: "https://www.hnzyxsbh.com/tripartite/web/xbhan/bhprocess",
      //   data: {
      //     applyno:bbc
      //   },
      //   crossDomain: true,
      //   contentType: "text/plain",
      //   type: "POST",
      //   dataType: "jsonp",
      // }).done(function (data) {
      //   // console.log("bhprocess",data)

      // });

      // return;

      var xieyic = sessionStorage.getItem('xieyi')
      const { data } = await bhprocess(bbc)
      // console.log("dinad底部打印", data.data.status);
      // ztype=>0:密文，1:等待还原，2:还原完成;billstatus=>0:未领取,1:申请中,2:已发放;status;0.等待申请1.等待支付 2.已经支付 3.等待签章 4.已经出函 5 已经退保
      if (data.code == 200) {
        if (!data.data.baohanno) return
        sessionStorage.setItem('baohanno', data.data.baohanno)

        if (data.data.status == 1) {
          if (xieyic == 2) {
            this.$router.push({
              name: 'project'
            })
          } else {
            //    this.$router.push({
            //   name: "shouxin",
            // });
          }
        }
        if (data.data.status == 2) {
          // 新加的
          // if (this.$route.path !== '/project/defray') {
          //   this.$router.push({
          //     name: 'project'
          //   })
          // }
          // 新加的
          // 原来的
          this.$router.push({
            name: 'project'
          })
          // 原来的
        }
        if (data.data.status == 3) {
          // console.log(this.$route.path);
          if (this.$route.path !== '/added') {
            this.$router.push({
              name: 'project'
            })
          }
        }
        // /added
        // "0":__('等待审核 待申请'),"1":__('等待支付 '),"2":__('已经支付'),"3":__('等待签约'),"4":__('已经出函') 5 （已经退保）
        if (data.data.status == 5) {
          this.$router.push({
            name: 'invoice'
          })
          // /project/invoice
          // this.tuibao = false;
          return
        }
        if (data.data.status == 4) {
          // this.$message("当前保函已出函");
          // // console.log(data.data.state,"跳转！！！！！")
          this.$router.push({
            name: 'defray'
          })
          // this.tuibao = false;
          return
        }
        if (data.data.status == 6) {
          // this.$message("当前保函已出函");
          // // console.log(data.data.state,"跳转！！！！！")
          this.$router.push({
            name: 'defray'
          })
          // this.tuibao = false;
          return
        }
      }
    },
    chuxians(e) {
      if (e == 1) {
        this.tangs = true
      } else {
        this.zhangs = true
      }
    },
    yincangs(e) {
      if (e == 1) {
        this.tangs = false
      } else {
        this.zhangs = false
      }
    }
  }
}
</script>

<style scoped>
.asdimgh {
  width: 39px;
  height: 39px;
  margin-right: 10px;
}
.bottomBox {
  margin-top: 3px;
  justify-content: center;
  display: flex;
  width: 100%;
  align-content: center;
  height: 58px;
  padding: 9px 0;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  color: #999;
  background: #fff;
}
.cinonimg {
  width: 100%;
  padding: 1% 15%;
  display: flex;
  justify-content: space-around;
}
.block {
  position: absolute;
  right: 30%;
  top: 82vh;
}
</style>
