<template>
  <div style="" class="toubu xiang">
    <div class="ieleft jue">
      <div style="" class="jue erqiwu">
        <img
          style="height: 60px; width: 400px;"
          src="@/assets/imges/90fe6e3a48949d2873e211e050690f1.png"
          alt=""
        />
        <img
          style="height: 40px; width: 400px; margin-left: 15px;"
          src="@/assets/logoxin.jpg"
          alt=""
        />
      </div>
    </div>

    <div class="toubuxin jue ieright" style="">
      <div style="" class="xin1 ieleft">
        <div v-if="tangs" class="block">
          <img
            style="width: 300px; height: 300px;"
            src="@/assets/login/tang1.png"
          />
        </div>

        <div class="ieleft" @mouseover="chuxians(1)" @mouseout="yincangs(1)">
          <img src="@/assets/login/tang.png" class="asdimgh" alt="" srcset="" />
        </div>
        <div class="xin2 ieright" style="">
          <div>业务联系人：唐先生</div>
          <div>联系电话：13140161000</div>
        </div>
      </div>
      <div class="xin3 ieright" style="">
        <div v-if="zhangs" class="block">
          <img
            style="width: 300px; height: 300px;"
            src="@/assets/login/zhang1.png"
          />
        </div>
        <div class="ieleft" @mouseover="chuxians(2)" @mouseout="yincangs(2)">
          <img
            src="@/assets/login/zhang.png"
            class="asdimgh"
            alt=""
            srcset=""
          />
        </div>
        <div style="" class="xin4 ieright">
          <div>技术联系人：张先生</div>
          <div>联系电话：13838271800</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tangs: false,
      zhangs: false,
      imgName: '../../assets/login/tang1.png'
    }
  },
  methods: {
    chuxians(e) {
      if (e == 1) {
        this.tangs = true
      } else {
        this.zhangs = true
      }
    },
    yincangs(e) {
      if (e == 1) {
        this.tangs = false
      } else {
        this.zhangs = false
      }
    }
  }
}
</script>

<style scoped>
.erqiwu {
  left: 0;
  height: 2.75rem;
  background: #069cff;
  border-radius: 0px 0px 0px 0px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0px;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */

  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */

  display: -ms-flexbox; /* TWEENER - IE 10 */

  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  /* 09版 */

  -webkit-box-pack: center;

  /* 12版 */

  -webkit-justify-content: center;

  -moz-justify-content: center;

  -ms-justify-content: center;

  -o-justify-content: center;
  justify-content: center;
  align-items: center;
  height: 69px;
  padding: 0 0%;
  background-color: #fff;
  justify-content: flex-start;
}
.toubuxin {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */

  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */

  display: -ms-flexbox; /* TWEENER - IE 10 */

  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  right: 10px;
  top: 20%;
}
.xin1 {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */

  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */

  display: -ms-flexbox; /* TWEENER - IE 10 */

  display: -webkit-flex; /* NEW - Chrome */
  display: flex;

  align-items: center;
  margin-left: 10px;
  position: relative;
}
.xin2 {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */

  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */

  display: -ms-flexbox; /* TWEENER - IE 10 */

  display: -webkit-flex; /* NEW - Chrome */
  display: flex;

  flex-direction: column;
  align-items: baseline;
}
.xin3 {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */

  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */

  display: -ms-flexbox; /* TWEENER - IE 10 */

  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  -js-display: flex;
  align-items: center;
  margin-left: 10px;
}
.xin4 {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */

  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */

  display: -ms-flexbox; /* TWEENER - IE 10 */

  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  -js-display: flex;
  flex-direction: column;
  align-items: baseline;
}
.toubu {
  background: #fff;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */

  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */

  display: -ms-flexbox; /* TWEENER - IE 10 */

  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  -js-display: flex;
  /* 09版 */

  -webkit-box-pack: center;

  /* 12版 */

  -webkit-justify-content: space-between;

  -moz-justify-content: space-between;

  -ms-justify-content: space-between;

  -o-justify-content: space-between;
  justify-content: space-between;
  padding: 0 7px;
  height: 70px;
}
.asdimgh {
  width: 39px;
  height: 39px;
  margin-right: 10px;
}
.bottomBox {
  margin-top: 3px;
  justify-content: center;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */

  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */

  display: -ms-flexbox; /* TWEENER - IE 10 */

  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  -js-display: flex;
  width: 100%;
  align-content: center;
  height: 58px;
  padding: 9px 0;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  color: #999;
  background: #fff;
}
.cinonimg {
  width: 100%;
  padding: 1% 15%;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */

  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */

  display: -ms-flexbox; /* TWEENER - IE 10 */

  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  -js-display: flex;
  justify-content: space-around;
}
.block {
  position: absolute;
  right: 30px;
  top: 60px;
  z-index: 9999999999;
}
</style>
